export const environment = {
  production: false,
  GRAPHQL_URL: 'https://b023g38i08.execute-api.eu-central-1.amazonaws.com/develop/portal/graphql',
  GRAPHQL_PROTECTED_URL: 'https://b023g38i08.execute-api.eu-central-1.amazonaws.com/develop/a-portal/graphql',
  GRAPHQL_SCHEMA_URL: 'https://b023g38i08.execute-api.eu-central-1.amazonaws.com/develop/portal/schema',
  BUILD_QUERY_KEY: '6a004d54ad43#14',
  CLOUDFRONT_API: 'https://d3hc0p9quv2wa0.cloudfront.net/develop/portal/graphql',
  TRACKING_URL: 'https://develop-tr.businessclassconsolidator.com',
  AWS_PROJECT_REGION: 'eu-central-1',
  AWS_COGNITO_REGION: 'eu-central-1',
  AWS_USER_POOLS_ID: 'eu-central-1_rv0HBBZOy',
  AWS_USER_POOLS_WEB_CLIENT_ID: '2af32uleuc8vdqrbkqnkkksjon',
  OAUTH_DOMAIN: 'develop-portal.auth.eu-central-1.amazoncognito.com',
  AMPLIFY_REDIRECT_SIGN_IN: 'https://develop-q.bcconsolidator.com/login',
  AMPLIFY_REDIRECT_SIGN_OUT: 'https://develop-q.bcconsolidator.com/logout',
};